<template>
  <order-detail-wrapper :status="info.exa_status">
    <section-title title="维修信息"></section-title>

    <van-cell title="IMEI号" :value="info.imei"></van-cell>
    <van-cell title="维修类型" :value="info.finish_type"></van-cell>
    <van-cell title="维修方式" :value="info.finish_mode"></van-cell>
    <van-cell title="维修金额" :value="info.money"></van-cell>

    <section-title title="维修信息"></section-title>

    <div class="remark">
      {{ info.remarks }}
      <div class="no-remark" v-if="!info.remarks">无备注信息</div>
    </div>

    <section-title title="维修完成照片"></section-title>

    <img-display2to1 :src="info.img"></img-display2to1>

    <section-title
      title="维修工单照片"
      v-if="info.finish_mode && info.finish_mode.indexOf('官方') !== -1"
      show-asterisk
    ></section-title>

    <img-display2to1 :src="info.work"></img-display2to1>

    <section-title title="订单信息"></section-title>
    <div class="order-info">
      <div class="info-item">
        <div class="info-name">维修编号</div>
        <div class="info-value">{{ info.finish_sn }}</div>
      </div>

      <div class="info-item">
        <div class="info-name">提交时间</div>
        <div class="info-value">{{ info.add_time }}</div>
      </div>

      <template v-if="info.exa_status === 1">
        <div class="info-item">
          <div class="info-name">审核通过时间</div>
          <div class="info-value">{{ info.exa_time }}</div>
        </div>

        <!--          <div class="info-item">-->
        <!--            <div class="info-name">快递地址</div>-->
        <!--            <div class="info-value">{{ info.send_address }}</div>-->
        <!--          </div>-->

        <div class="info-item">
          <div class="info-name">商家审核内容</div>
          <div class="info-value">{{ info.fail_exp }}</div>
        </div>
      </template>

      <template v-if="info.exa_status === 2">
        <div class="info-item">
          <div class="info-name">未通过原因</div>
          <div class="info-value">{{ info.fail_exp }}</div>
        </div>
      </template>
    </div>

    <my-button
      class="edit-order"
      @click="editOrder"
      v-if="info.exa_status === 2"
      >编辑订单</my-button
    >
  </order-detail-wrapper>
</template>

<script>
import { finishOrder } from "@/api/repairInfo";

export default {
  name: "detail",
  props: {
    id: String,
  },
  data() {
    return {
      info: "",
    };
  },
  mounted() {
    finishOrder(this.id).then((res) => {
      let title;
      switch (res.data.exa_status) {
        case 0:
          title = "审核中";
          break;
        case 1:
          title = "审核通过";
          break;
        case 2:
          title = "审核未通过";
          break;
      }
      document.title = title;
      this.info = res.data;
    });
  },
  methods: {
    editOrder() {
      this.$router.push("/RepairInfoAdd/" + this.info.claims_id);
      sessionStorage.setItem("RepairId", this.id);
    },
  },
};
</script>

<style scoped lang="less">
.page-container {
  .van-cell__value {
    color: #333;
  }
}

.remark {
  width: calc(100vw - 60px);
  padding: 26px;
  background: #f6f8fe;
  border-radius: 16px;
  font-size: 28px;
  font-weight: 400;
  color: #333333;
}

.no-remark {
  font-size: 24px;
  color: #999;
  text-align: center;
}

.img {
  width: calc(100vw - 60px);
  height: 345px;
  border-radius: 16px;
}

.order-info {
  .remark();

  .info-item {
    display: flex;
    min-height: 60px;

    .info-name {
      min-width: 200px;
      font-size: 28px;
      font-weight: 500;
      white-space: nowrap;
      color: #666;
    }

    .info-value {
      color: #333;
      font-size: 28px;
      word-break: break-all;
    }
  }
}

.edit-order {
  width: calc(100vw - 150px);
  margin: 50px auto;
}
</style>
